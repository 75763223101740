import React, { useEffect, useState } from "react";
import { useImage } from "../Hooks/ImageContext";
import ImageArray from "../Hooks/ImageArray";

const Examples = () => {
  const { selectedImage, selectImage } = useImage();
  const [initialLoad, setInitialLoad] = useState(true);

  const handleImageClick = (image) => {
    window.scrollTo(0, 0);
    selectImage(image);
  };

  useEffect(() => {
    if (!initialLoad && selectedImage) {
      // Scroll to the Display component when selectedImage changes
      const displaySection =
        document.getElementsByClassName("banner-container");

      if (displaySection && displaySection.length > 0) {
        displaySection[0].scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Set initialLoad to false after the first render
      setInitialLoad(false);
    }
  }, [selectedImage, initialLoad]);

  return (
    <div className="examples-card-container">
      {ImageArray.map((image, index) => (
        <div
          key={index}
          className="examples-card"
          style={{
            border: selectedImage === image ? "2px solid black" : "none",
            backgroundColor: selectedImage === image ? "lightblue" : "white",
          }}
          onClick={() => handleImageClick(image)}
        >
          <img src={image.src} alt={image.title} />
          <p>{image.price}</p>
        </div>
      ))}
    </div>
  );
};

export default Examples;
