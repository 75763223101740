import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";

const Carousel = ({
  images,
  onCurrentImageChange,
  currentImageIndex,
  selectedImage,
}) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // Check the screen width and update the state accordingly
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 960);
    };

    // Initial check on component mount
    handleResize();

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // React swipeable handlers
  const handlers = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => prevImage(),
    preventDefaultTouchmoveEvent: true,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const currentImage = images[currentImageIndex];

  const openEmail = () => {
    if (currentImage && currentImage.title) {
      const body = encodeURIComponent(
        "MAKE SURE TO PRESS SEND ON THIS EMAIL TO PLACE AN ORDER REQUEST \n\nDear Customer,\n\n Thank you for requesting an order with me! MAKE SURE TO PRESS SEND ON THIS EMAIL, so I'll know to que your order and reach out to you. I appreciate your business. I'll be in contact with you within 3 business days to provide further details.\n\nIn the meantime, if you have any questions, feel free to type out a message below, and I'll get back to ya.\n\nBest regards,\nShawns Shop"
      );

      // Your email body content here

      window.location.href = `mailto:shawn417shop@gmail.com?subject=${encodeURIComponent(
        currentImage.title
      )}&body=${body}`;
    } else {
      console.error("Please select an Item");
    }
  };

  const nextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    onCurrentImageChange(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
    onCurrentImageChange(prevIndex);
  };

  return (
    <div
      className={`carousel-container ${isMobileView ? "mobile-view" : ""}`}
      {...handlers}
    >
      <div className="main-image">
        {currentImage && (
          <img
            src={currentImage.src}
            alt={currentImage.title}
            className="image-slide"
          />
        )}
        <div className="dots-container">
          <div className="dots-inner-container">
            {images.map((_, index) => (
              <div
                key={index}
                className={`dot ${currentImageIndex === index ? "active" : ""}`}
                onClick={() => onCurrentImageChange(index)}
                alt={`Image dot ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`image-info-container ${isMobileView ? "mobile-view" : ""}`}
      >
        {currentImage && (
          <div className="product-info">
            <h2>{currentImage.title}</h2>
            <p className="product-price">{currentImage.price}</p>
            <p className="product-description">{currentImage.description}</p>
            <button onClick={openEmail} className="add-to-cart-btn">
              Email an Order Request
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
