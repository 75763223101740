const ImageArray = [
  {
    src: process.env.PUBLIC_URL + "/images/shop.jpg",
    title: "Image Title 1",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$120.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wood.jpg",
    title: "Image Title 2",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$124.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wheat.jpg",
    title: "Image Title 3",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$100.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wood.jpg",
    title: "Image Title 4",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$120.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wheat.jpg",
    title: "Image Title 6",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$100.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wood.jpg",
    title: "Image Title 5",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$124.99",
  },

  {
    src: process.env.PUBLIC_URL + "/images/wheat.jpg",
    title: "Image Title 9",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$100.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/shop.jpg",
    title: "Image Title 7",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$120.99",
  },
  {
    src: process.env.PUBLIC_URL + "/images/wood.jpg",
    title: "Image Title 8",
    description: `Experience the perfect blend of resilience and durability with our
                  handmade bullwhip, crafted from athletic tape and paracord, a unique
                  fusion of functionality and artisanal precision.`,
    price: "$124.99",
  },
];

export default ImageArray;
