import React from "react";
import "./App.css";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import Display from "./Components/Display";
import { ImageProvider } from "./Hooks/ImageContext";
import Examples from "./Components/Examples";
/*import ShoppingCart from "./Components/ShoppingCart";*/

function App() {
  return (
    <div className="app">
      <ImageProvider>
        {/*<ShoppingCart />*/}
        <Banner />
        <Display />
        <Examples />
        <Footer />
      </ImageProvider>
    </div>
  );
}

export default App;
