import React, { useState, useEffect } from "react";
import Carousel from "./Carousel";
import ImageArray from "../Hooks/ImageArray";
import { useImage } from "../Hooks/ImageContext";

const Display = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { selectedImage, selectImage } = useImage();

  useEffect(() => {
    const newIndex = ImageArray.findIndex((image) => image === selectedImage);
    if (newIndex !== -1) {
      setCurrentImageIndex(newIndex);
    }
  }, [selectedImage, setCurrentImageIndex]);

  useEffect(() => {
    function handleResize() {
      const carouselContainer = document.querySelector(".carousel-container");
      if (carouselContainer) {
        if (window.innerWidth <= 960) {
          carouselContainer.classList.add("mobile-view");
        } else {
          carouselContainer.classList.remove("mobile-view");
        }
      }
    }

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const handleCurrentImageChange = (index) => {
    setCurrentImageIndex(index);
    selectImage(ImageArray[index]);
  };

  const nextImage = () => {
    const nextIndex = (currentImageIndex + 1) % ImageArray.length;
    setCurrentImageIndex(nextIndex);
    selectImage(ImageArray[nextIndex]);
  };

  const prevImage = () => {
    const prevIndex =
      (currentImageIndex - 1 + ImageArray.length) % ImageArray.length;
    setCurrentImageIndex(prevIndex);
    selectImage(ImageArray[prevIndex]);
  };

  return (
    <div className="display-container">
      <button className="prev-button" onClick={prevImage}>
        &lt;
      </button>
      <div className="product-image">
        <Carousel
          images={ImageArray}
          onCurrentImageChange={handleCurrentImageChange}
          currentImageIndex={currentImageIndex}
          selectedImage={selectedImage}
        />
      </div>
      <button className="next-button" onClick={nextImage}>
        &gt;
      </button>
    </div>
  );
};

export default Display;
