import { FaPhoneAlt, FaMailBulk } from "react-icons/fa";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-text">
        <div className="icon-text-position">
          <h3>
            <FaPhoneAlt /> &nbsp; (555)5555-5555
          </h3>
        </div>
        <div className="icon-text-position">
          <h3>
            <FaMailBulk /> &nbsp; shawn417shop@gmail.com
          </h3>
        </div>
      </div>
      <div className="footer-copyright">
        <h1>© 2024 shawns417. All rights reserved. </h1>
      </div>
    </div>
  );
}

export default Footer;
