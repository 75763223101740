import React, { useRef } from "react";
function Banner() {
  const displayRef = useRef(null);
  return (
    <div className="banner-container" ref={displayRef}>
      <div className="banner-text">
        <h1>Welcome to Shawns 417</h1>
        <p>Hand Crafted, Made For Use</p>
      </div>
      {/* You can add images or icons here if needed */}
    </div>
  );
}

export default Banner;
